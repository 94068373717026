
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
import { Profile, GetSupport } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer'; 
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';  

import { useTranslation } from 'react-i18next';

export default function My() {  
    const { t } = useTranslation();
    const { i18n } = useTranslation(); 
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();  
    const [balance, setBalance] = useState(0); 
    const [username, setUsername] = useState(0); 
    const [tel, setTel] = useState(0); 
    const [invitecode, setInvitecode] = useState(0);  
    const [supportUrl, setSupportUrl] = useState('');
    const [tgUrl, setTgUrl] = useState('');

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }   
        UserProfile();
        GetSupportInfo();
  
        let currentLanguage = i18n.language;
        let currentLang = localStorage.getItem('lang');  
        if(currentLanguage != currentLang){ 
            i18n.changeLanguage(currentLang);
        } 
        // eslint-disable-next-line
    }, []); 
   
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setUsername(data.username);
            setBalance(data.balance);
            setInvitecode(data.invite_code);
            let mobilecode = data.mobilecode;
            let tel = data.tel; 
            setTel(tel)

            let currentLang = localStorage.getItem('lang');  
            if(data.lang){
                if(currentLang !== data.lang){  
                    localStorage.setItem('lang', data.lang);  
                }
            }
             
            let currentLanguage = i18n.language; 
            if(currentLanguage != currentLang){ 
                i18n.changeLanguage(currentLang);
            } 
            console.log("currentLanguage="+currentLanguage)
            console.log("lang="+currentLang)
        } 
    } 
    const GetSupportInfo = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo;  
            // let pay_name = "trc20"; 
            GetSupport({}, CallbackGetSupport);  
        }
    } 
    
    const CallbackGetSupport = (returnData) => {  
        if(returnData.code == 1)
        {  
            var data = returnData.data; 
            setTgUrl(data.tg);   
            setSupportUrl(data.liveChat);   
        }  
    } 
    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
 
    const handleBank = (e) => { 
        e.preventDefault();
        navigate('/bank');
    }; 
    const handlePassword = (e) => { 
        e.preventDefault();
        navigate('/password');
    }; 
    const handlePasswordWithdraw = (e) => { 
        e.preventDefault();
        navigate('/withdraw-password');
    }; 
    
    const handleLanguage = (e) => { 
        e.preventDefault();
        navigate('/language');
    }; 

    const handleRealname = (e) => { 
        e.preventDefault();
        navigate('/realname');
    }; 

    const handleInvite = (e) => { 
        e.preventDefault();
        navigate('/invite');
    }; 
    const handleWithdrawalHistory = (e) => { 
        e.preventDefault();
        navigate('/withdrawal-history');
    }; 
    const handleDepositHistory = (e) => { 
        e.preventDefault();
        navigate('/deposit-history');
    }; 
    const handleService = (e) => { 
        e.preventDefault();
        navigate('/service');
    }; 
    const handleProfile = (e) => { 
        e.preventDefault();
        navigate('/profile');
    }; 

    const handleDeposit = (e) => { 
        e.preventDefault();
        navigate('/deposit');
        // navigate('/deposits/trc20');
    }; 
    const handleWithdrawal = (e) => { 
        e.preventDefault();
        navigate('/withdrawal');
    }; 

    const handleRecord = (e) => { 
        e.preventDefault();
        navigate('/record');
    }; 

    const handleAccount = (e) => { 
        e.preventDefault();
        navigate('/transaction-logs');
    }; 
    const handleMsg = (e) => { 
        e.preventDefault();
        navigate('/msg');
    }; 
    const handleReport = (e) => { 
        e.preventDefault();
        navigate('/team-report');
    }; 
    const handleHelp = (e) => { 
        e.preventDefault();
        window.open(supportUrl, '_blank'); 
    }; 
    const handlePolicy = (e) => { 
        e.preventDefault();
        navigate('/privacy-policy');
    }; 
    const handleCopy = (e) => { 
        e.preventDefault();
        navigate('/terms-conditions');
    }; 
    const handleQNA = (e) => { 
        e.preventDefault();
        navigate('/q-n-a');
    }; 
    const handleAbout = (e) => { 
        e.preventDefault();
        navigate('/about');
    }; 

    const divStyle = { 
        backgroundImage: 'url(/images/banner.png)',
        backgroundPosition: 'top center',    
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
    }; 
    const cardStyle = { 
        backgroundImage: 'url(/images/card.png)',
        backgroundPosition: 'top center',    
        backgroundSize: 'contain',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat'
    }; 

    
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleLogout = () => {
        setOpenConfirm(true);
    };
  
    const handleCloseReject = () => {
        setOpenConfirm(false);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false); 
        logout();
    };
    const logout = () => {
        localStorage.clear();
        navigate('/login');
    };
   

  return (
    <>
        <div className="page-wrapper my-wrapper">  

        
            <div className="module"> 
                <div className="page-title"> 
                    <h4>{t("Profile")}</h4>
                </div>
                <div className="card app_wrapper" style={cardStyle}>  
                    <div className="card_info">
                        <div className="balance_info">
                            <h4>{t("USDT")}</h4>
                            <h2>${balance}</h2>
                        </div>
        
                        <div className="wallet-action"> 
                            <div className="btn btn-recharge" onClick={handleDeposit}   > 
                                <span>{t('Recharge')}</span>
                            </div> 
                            <div className="btn btn-withdraw" onClick={handleWithdrawal}  > 
                                <span>{t('Withdraw')}</span>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
  
            <div className="module app_wrapper"> 
                <div className="home-header"> 
                    <div className="home-info-col"> 
                        <img src={"/images/head.png"} height={50} alt={t('Profile')}/>
                    </div>
                    <div className="home-info-col"> 
                        <h4>{username} {tel ? <small>{tel}</small> : ''}</h4>
                        <h5>{t('Invite Code')}:{invitecode}</h5>
                    </div>
                    <div className="home-info-col"> 
                        <span className="btn-profile" onClick={handleProfile} ><i className="fa-regular fa-pen-to-square"></i></span>
                    </div>
                </div>
            </div>

            <div className="module"> 
                <div className="profile_banner app_wrapper" style={divStyle}> 
                    <img src={"/images/premium.png"} alt={t('Banner')} height="40"/>
                    <div className="banner_info">
                        <h2>{t("Premium Member")}</h2>
                        <h4>{t("New movies are coming for you, Download Now!")}</h4>
                    </div>
                </div>
            </div>
 
            <div className="module">
                <div className="app_wrapper">
                    <div className="profile_nav">
                        <h4>{t("Account")}</h4>
                        <ul>
                            <li>
                                <span className="nav-link"  onClick={handleProfile}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-regular fa-user"></i></span>
                                        <span>{t("Profile")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link"  onClick={handleRecord}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-solid fa-clipboard"></i></span>
                                        <span>{t("Record")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link" onClick={handleAccount}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-solid fa-bars-staggered"></i></span>
                                        <span>{t("Account")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link"  onClick={handleDepositHistory}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-solid fa-money-check-dollar"></i></span>
                                        <span>{t("Recharge List")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link"  onClick={handleWithdrawalHistory}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-solid fa-money-bill-transfer"></i></span>
                                        <span>{t("Withdraw List")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link" onClick={handleReport}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-solid fa-chart-simple"></i></span>
                                        <span>{t("Team Report")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="profile_nav">
                        <h4>{t("More")}</h4>
                        <ul>
                            <li> 
                                <span className="nav-link"  onClick={handleLanguage}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-solid fa-language"></i></span>
                                        <span>{t("Language")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li> 
                            <li> 
                                <span className="nav-link"  onClick={handleAbout}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-solid fa-info"></i></span>
                                        <span>{t("About")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li> 
                            <li> 
                                <span className="nav-link"  onClick={handleQNA}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-regular fa-circle-question"></i></span>
                                        <span>{t("Q&A")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li> 

                            <li>
                                <span className="nav-link"  onClick={handlePolicy}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-solid fa-umbrella"></i></span>
                                        <span>{t("Legal and Policies")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span className="nav-link"  onClick={handleCopy}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-solid fa-shield"></i></span>
                                        <span>{t("Terms of Service")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li> 
                            <li>
                                <span className="nav-link" onClick={handleHelp}>
                                    <span className="nav-title">
                                        <span className="nav-icon"><i className="fa-regular fa-message"></i></span>
                                        <span>{t("Help & Customer Service")}</span>
                                    </span>
                                    <span className="nav-badge">
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </span>
                                </span>
                            </li>  
                        </ul>
                    </div>
                </div>
            </div>

 
            <div className="module">
                <div className="app_wrapper mt-5"> 
                    <a href="#" className="btn btn-primary btn-block" onClick={handleLogout}> 
                        <span>{t("Log Out")}</span>
                    </a>
                </div>
            </div>
   
        <Footer/>
        </div> 

        
  
        <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        > 
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {t('Are you sure to exit??')}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseReject}>{t('Cancel')}</Button>
            <Button onClick={handleCloseConfirm} autoFocus>
            {t('Confirm')}
            </Button>
            </DialogActions>
        </Dialog>
    </>
  );
};