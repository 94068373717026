import axios from 'axios';
import {encryptData, decryptData, genSign} from './Utils'
  
// const baseUrl = process.env.REACT_APP_API_URL; 
const baseUrl = parseDomain();

function parseDomain(){ 
    const currentURL = window.location.href;
    const parsedUrl = new URL(currentURL);
    const domainMappings = {
        "mgmomovie.com": "https://mgmoapi.mgmomovies.com/api",
        // "mgmomovie.com": "https://api.mgmomovie.com/api",
        "gzwxmovie.com": "https://api.gzwxmovie.com/api",
        "imovieset.com": "https://api.imovieset.com/api",
        "ipmovie-hd.com": "https://api.ipmovie-hd.com/api",
        "omovieo.com": "https://api.omovieo.com/api",
        "ykanmovie.com": "https://api.ykanmovie.com/api",
        "onlinemoviesshow.com": "https://api.onlinemoviesshow.com/api",
        "seaskyfilm.com": "https://api.seaskyfilm.com/api",
    };

    let myurl2 = process.env.REACT_APP_API_URL; 
    const myurl = domainMappings[parsedUrl.hostname] || myurl2;
 
    return myurl; 
}

export function sendapi(apiUrl, params, callback){
    
    // let suffix = 123456;
    let suffix = Math.floor(100000 + Math.random() * 900000);
    if(process.env.REACT_APP_ENV === 'dev')
    {
        suffix = 123456;
    }
    let lang    = localStorage.getItem('lang') || 'en';
    
    let headers = {
        'Content-Type': 'application/json',
        'imei': suffix,
        'suffix': suffix,
        'lang': lang
    }
    if(params.token !== undefined)
    {
        headers.token = params.token
    }   
    params.timestamp = Math.round(Date.now()/1000) 
    params.encode_sign = genSign(params); 
    
    let app_lock = process.env.REACT_APP_LOCK;  

    let postData = params
    if(app_lock === "true")
    {
        let encrypted = encryptData(params, suffix)
        postData = {
            "post-data": encrypted
        }
    } 
 
    if(process.env.REACT_APP_ENV === 'dev')
    {
        console.log("apiUrl")
        console.log(apiUrl)
        console.log("params")
        console.log(params)
        console.log("postData")
        console.log(postData) 
        console.log("token")
        console.log(params.token)
    }
    axios({
        method: 'post',
        url: apiUrl,
        timeout: 30000,    // 4 seconds timeout
        data: postData,
        headers: headers
    })
    .then(res  => { 
        let app_lock = process.env.REACT_APP_LOCK;  
        var retunData = res.data; 
        var finalData = ""
        if(app_lock === "true")
        {  
            var decrypted = decryptData(retunData.data, retunData.suffix)   
            if(decrypted !== null)
            {
                decrypted = JSON.parse(decrypted); 
                if(process.env.REACT_APP_ENV === 'dev')
                {
                    console.log("decrypted")
                    console.log(decrypted)
                }
                finalData = decrypted.data;
            }
            else{             
                finalData = {code: 9001, message: 'Decrypt Data Error'} 
            }
        } 
        else{ 
            finalData = retunData.data;
        }  
        if(process.env.REACT_APP_ENV === 'dev')
        { 
            console.log("return")
            console.log(finalData)
        }
        callback(finalData)
    })
    .catch(err => {
        // Handle error
        console.log(err);
        callback(err)
    });
}
  
 
export const checkToken = () => {  
    let tokenInfo = localStorage.getItem('token');    
    if(tokenInfo == null){ 
        return false;
    }
    return true;
};

export function UserLogin(params, callback){
    let apiUrl = baseUrl + '/user/login'; 
    return sendapi(apiUrl, params, callback) 
}

export function UserRegister(params, callback){
    let apiUrl = baseUrl + '/user/register'; 
    return sendapi(apiUrl, params, callback) 
}

export function Profile(params, callback){ 
    let apiUrl = baseUrl + '/user/profile';
    return sendapi(apiUrl, params, callback)  
}

export function TradeOrder(params, callback){ 
    let apiUrl = baseUrl + '/trade/submitOrder'; 
    return sendapi(apiUrl, params, callback)  
}

export function RotOrder(params, callback){  
    let apiUrl = baseUrl + '/trade/rotOrder'; 
    return sendapi(apiUrl, params, callback)  
}

export function GetOrder(params, callback){  
    let apiUrl = baseUrl + '/trade/getOrder'; 
    return sendapi(apiUrl, params, callback)  
}
export function GetOrderDetail(params, callback){  
    let apiUrl = baseUrl + '/trade/orderInfo'; 
    return sendapi(apiUrl, params, callback)  
}
export function PostOrderDetail(params, callback){  
    let apiUrl = baseUrl + '/trade/doOrder'; 
    return sendapi(apiUrl, params, callback)  
}


export function GetBank(params, callback){  
    let apiUrl = baseUrl + '/user/getBank'; 
    return sendapi(apiUrl, params, callback)  
}
export function SetBank(params, callback){  
    let apiUrl = baseUrl + '/user/setBank'; 
    return sendapi(apiUrl, params, callback)  
}
export function SetName(params, callback){  
    let apiUrl = baseUrl + '/user/setName'; 
    return sendapi(apiUrl, params, callback)  
}
export function UpdatetLang(params, callback){  
    let apiUrl = baseUrl + '/user/setLang'; 
    return sendapi(apiUrl, params, callback)  
}
export function Pages(params, callback){  
    let apiUrl = baseUrl + '/common/pages'; 
    return sendapi(apiUrl, params, callback)  
}
export function GetDeposit(params, callback){  
    let apiUrl = baseUrl + '/common/getDeposit'; 
    return sendapi(apiUrl, params, callback)  
}
export function PostDeposit(params, callback){  
    let apiUrl = baseUrl + '/user/postDeposit'; 
    return sendapi(apiUrl, params, callback)  
}
export function PostWithdraw(params, callback){  
    let apiUrl = baseUrl + '/user/postWithdraw'; 
    return sendapi(apiUrl, params, callback)  
}
export function PostConvert(params, callback){  
    let apiUrl = baseUrl + '/user/postConvert'; 
    return sendapi(apiUrl, params, callback)  
}
export function SetPassword(params, callback){  
    let apiUrl = baseUrl + '/user/setPassword'; 
    return sendapi(apiUrl, params, callback)  
}
export function SetWithdrawPassword(params, callback){  
    let apiUrl = baseUrl + '/user/setWithdrawPassword'; 
    return sendapi(apiUrl, params, callback)  
}
export function DepositHistory(params, callback){  
    let apiUrl = baseUrl + '/user/depositHistory'; 
    return sendapi(apiUrl, params, callback)  
}
export function WithdrawHistory(params, callback){  
    let apiUrl = baseUrl + '/user/withdrawHistory'; 
    return sendapi(apiUrl, params, callback)  
}
export function Banner(params, callback){  
    let apiUrl = baseUrl + '/common/banner'; 
    return sendapi(apiUrl, params, callback)  
}
export function BannerVersion(params, callback){  
    let apiUrl = baseUrl + '/common/bannerVersion'; 
    return sendapi(apiUrl, params, callback)  
}
export function PageVersion(params, callback){  
    let apiUrl = baseUrl + '/common/pageVersion'; 
    return sendapi(apiUrl, params, callback)  
}
export function vipList(params, callback){  
    let apiUrl = baseUrl + '/trade/vipList'; 
    return sendapi(apiUrl, params, callback)  
}
export function transactionHistory(params, callback){  
    let apiUrl = baseUrl + '/user/transactionHistory'; 
    return sendapi(apiUrl, params, callback)  
}
export function teamReport(params, callback){  
    let apiUrl = baseUrl + '/user/teamReport'; 
    return sendapi(apiUrl, params, callback)  
}
export function country(params, callback){  
    let apiUrl = baseUrl + '/common/country'; 
    return sendapi(apiUrl, params, callback)  
}
export function countryVersion(params, callback){  
    let apiUrl = baseUrl + '/common/countryVersion'; 
    return sendapi(apiUrl, params, callback)  
}
export function termsCondition(params, callback){  
    let apiUrl = baseUrl + '/common/termsCondition'; 
    return sendapi(apiUrl, params, callback)  
}
export function privacyPolicy(params, callback){  
    let apiUrl = baseUrl + '/common/privacyPolicy'; 
    return sendapi(apiUrl, params, callback)  
} 
export function GetSupport(params, callback){  
    let apiUrl = baseUrl + '/common/getSupport'; 
    return sendapi(apiUrl, params, callback)  
}
export function countryCode(params, callback){  
    let apiUrl = baseUrl + '/common/countryCode'; 
    return sendapi(apiUrl, params, callback)  
}